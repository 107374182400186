<template>
    <div v-if="authUserPermission['mcr-material-request-view']">
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header mt--4">
                    <div class="row">
                        <div class="col">
                            <h3 class="mb-0">{{ tt('material_request_list') }}</h3>
                        </div>
                        <div class="text-right mr-3">
                            <base-button size="sm" type="default" @click="filter">{{ tt('filter') }}</base-button>
                        </div>
                    </div>
                </div>
                <div>
                    <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                        <el-table-column :label="tt('type')" :prop="tt('type')" min-width="152px" sortable>
                            <template v-slot="{row}">
                                {{row.type}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('mcr_number')" :prop="tt('mcr_number')" min-width="159px" sortable>
                            <template v-slot="{row}">
                                {{row.mcr_number}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('subject')" :prop="tt('subject')" min-width="150px" sortable>
                            <template v-slot="{row}">
                                {{row.subject}}
                            </template>
                        </el-table-column>
                        <!-- <el-table-column :label="tt('revision')" :prop="tt('revision')" min-width="144px" sortable>
                            <template v-slot="{row}">
                                {{row.revision}}
                            </template>
                        </el-table-column> -->
                        <el-table-column :label="tt('created_by')" :prop="tt('created_by')" min-width="152px" sortable>
                            <template v-slot="{row}">
                                {{row.created_by}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('created_at')" :prop="tt('created_at')" min-width="191px" sortable>
                            <template v-slot="{row}">
                                {{moment(row.created_at).locale('id').format('LLL')}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('day')" :prop="tt('day')" min-width="110px" sortable>
                            <template v-slot="{row}">
                                {{moment(row.created_at).locale('id').fromNow()}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('total_item')" :prop="tt('total_item')" min-width="158px" sortable>
                            <template v-slot="{row}">
                                {{row.count_item_count}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('status')" :prop="tt('status')" min-width="123px" sortable>
                            <template v-slot="{row}">
                                <label class="badge badge-primary" v-if="row.status == 'On process'">ON PROCESS</label>
                                <label class="badge badge-success" v-else-if="row.status == 'Approved'">APPROVED</label>
                                <label class="badge badge-danger" v-else-if="row.status == 'Rejected'">REJECTED</label>
                                <label class="badge badge-warning" v-else-if="row.status == 'Revised'">REVISED</label>
                            </template>
                        </el-table-column>
                        <el-table-column prop="action" width="100">
                            <template v-slot="{row}">
                                <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true">
                                    <span class="btn btn-sm btn-icon-only text-light">
                                        <i class="fas fa-ellipsis-v mt-2"></i>
                                    </span>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                        <router-link :to="'/material/mcr-form/'+row.mcr_code+'/'+row.token+'/detail'" class="dropdown-item ml-1" tag="button">{{ tt('detail') }}</router-link> 
                                        <el-dropdown-item :command="{action:'tracking', data:{mcr_code:row.mcr_code}}">{{ tt('tracking') }}
                                        </el-dropdown-item>                                     
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                <page-loading v-else/>
                    </div>  
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>
        <modal :show.sync="form.show">
            <template slot="header">
                <h5 class="modal-title">{{form.title}}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('subject') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('subject')" :placeholder="tt('subject')" v-model="materialRequest.subject" rules="required"></base-input>

                <label class="form-control-label">{{ tt('type') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('type')" rules="required">
                    <el-select class="select-danger" v-model="materialRequest.type" :placeholder="tt('choose_type')">
                        <el-option class="select-danger" value="1" label="New Material" key="New Material"></el-option>
                        <el-option class="select-danger" value="2" label="Material Revision" key="Material Revision"></el-option>
                    </el-select>
                </base-input>

                <label class="form-control-label">{{ tt('catalog_type') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('catalog_type')" rules="required">
                    <el-select class="select-danger" v-model="materialRequest.catalogType" :placeholder="tt('choose_catalog_type')">
                        <el-option class="select-danger" value="1" label="SIT - SIT" key="SIT - SIT"></el-option>
                        <el-option class="select-danger" value="2" label="SWH - SWH" key="SWH - SWH"></el-option>
                    </el-select>
                </base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                <base-button type="primary">{{ tt('add') }}</base-button>
            </template>
        </modal>

        <!-- MODAL TRACKING  -->
        <modal :show.sync="formTracking.show" size="lg">
            <template slot="header">
                  <h5 class="modal-title">{{ tt('approval_tracking') }}</h5>
              </template>
              <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.tracking" v-if="!onLoadTracking">
                  <el-table-column :label="tt('date')" :prop="tt('date')" min-width="100px" sortable>
                      <template v-slot="{row}">
                          {{ row.created_at }}
                      </template>
                  </el-table-column>
                  <el-table-column :label="tt('status')" :prop="tt('status')" min-width="100px" sortable>
                      <template v-slot="{row}">
                          {{ row.message }}
                      </template>
                  </el-table-column>
                  <el-table-column :label="tt('approver')" :prop="tt('approver')" min-width="100px" sortable>
                      <template v-slot="{row}">
                          {{ row.name }}
                      </template>
                  </el-table-column>
                  <el-table-column :label="tt('message')" :prop="tt('message')" min-width="100px" sortable>
                      <template v-slot="{row}">
                          {{ row.note }}
                      </template>
                  </el-table-column>
              </el-table>
              <page-loading v-else/>
        </modal>

        <!-- MODAL FILTER -->
        <modal :show.sync="formFilter.show">
            <template slot="header">
                <h5 class="modal-title">{{ tt('filter') }}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('type') }}</label>
                <base-input :name="tt('type')">
                    <el-select class="select-danger" v-model="table.search.type" :placeholder="tt('choose_type')">
                        <el-option class="select-danger" :value="mf['form_code']" :label="mf['form_code'] + ' - ' + mf['form_description']" :key="mf['form_code'] + ' - ' + mf['form_description']" v-for="mf in  masterForm"></el-option>
                    </el-select>
                </base-input>

                <label class="form-control-label">{{ tt('mcr_number') }}</label>
                <base-input :name="tt('mcr_number')" :placeholder="tt('mcr_number')" v-model="table.search.mcr_number"></base-input>

                <label class="form-control-label">{{ tt('subject') }}</label>
                <base-input :name="tt('subject')" :placeholder="tt('subject')" v-model="table.search.subject"></base-input>

                <label class="form-control-label">{{ tt('created_by') }}</label>
                <base-input :name="tt('created_by')" :placeholder="tt('created_by')" v-model="table.search.created_by"></base-input>

                <label class="form-control-label">{{ tt('created_at') }}</label>
                <base-input type="date" :name="tt('created_at')" :placeholder="tt('created_at')" v-model="table.search.created_at"/>

                <label class="form-control-label">{{ tt('status') }}</label>
                <base-input :name="tt('status')">
                    <el-select class="select-danger" v-model="table.search.status" :placeholder="tt('choose_status')">
                        <el-option class="select-danger" value="On process" label="On process" key="On process"></el-option>
                        <el-option class="select-danger" value="Approved" label="Approved" key="Approved"></el-option>
                        <el-option class="select-danger" value="Rejected" label="Rejected" key="Rejected"></el-option>
                    </el-select>
                </base-input>
            </div>
            <template slot="footer">
                <base-button type="primary" v-on:click="filtering" :disabled="btnFilter.onLoading">
                    <span v-if="btnFilter.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                    <span v-else>
                        {{ tt('filter') }}
                    </span>
                </base-button>
            </template>
        </modal>
    </div>
    <noaccess v-else/>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import materialRequestList from '@/services/material/materialRequestList.service';
    import form from '@/services/setting/form.service';
    import draftList from "@/services/material/draftList.service";
    var moment = require('moment');

    export default {        
        data() {
            return { 
                onLoadTracking: true,
                moment:moment,
                onLoad: true,                 
                form: {
                    title: this.tt('add_new_mcr'),
                    show: false
                },
                btnFilter: {
                    onLoading: false
                },
                formFilter: {
                    show: false
                },  
                formTracking: {
                    show: false
                }, 
                table: {
                    search: {
                        type: '',
                        mcr_number: '',
                        subject: '',
                        created_by: '',
                        created_at: '',
                        status: '',
                    },                      
                    total: 0,
                    data: [],
                    page: [],
                    tracking: []
                },        
                materialRequest: {
                    
                },
                masterForm: {},
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get();
            this.getForm();
        },
        methods: {
            get() { 
                let context = this;               
                Api(context, materialRequestList.index(this.table.page.current_page, {search: this.table.search})).onSuccess(function(response) {    
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data;  
                    context.table.page  = response.data.data.data; 
                    context.onLoad = false;      
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                })
                .call()
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'tracking':
                            this.tracking(command.data.mcr_code);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.form.show = true;
            },
            filter() {
                this.formFilter.show = true
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    if (result.value) {
                        this.alertDialog('success', this.tt('success_delete'));
                    } else {
                        this.alertDialog('error', this.tt('error_delete'));
                    }
                })
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 10);
            },
            filtering() {
                let context = this;
                Api(context, materialRequestList.index('none', {type: context.table.search.type, mcr_number: context.table.search.mcr_number, subject: context.table.search.subject, created_by: context.table.search.created_by, created_at: context.table.search.created_at, status: context.table.search.status })).onSuccess(function(response) {    
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data;  
                    context.table.page  = response.data.data.data; 
                    context.onLoad = false; 
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                }).onFinish(function() {
                    context.onLoad = false;
                    context.btnFilter.onLoading = false;
                    context.formFilter.show = false;
                }).call()
            },
            getForm() {
                let context = this;               
                Api(context, form.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.masterForm = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.masterForm = [];
                    }
                })
                .call()
            },
            tracking(mcr_code) {
                this.formTracking.show = true;

                let context = this;
                Api(context, draftList.log_approval(mcr_code))
                .onSuccess(function(response) {
                    context.table.tracking = response.data.data;
                })
                .onError(function(error) {
                    if (error.response.status == 404) {
                      context.table.tracking = [];
                    }
                })
                .onFinish(function() {
                    context.onLoadTracking = false;
                })
                .call();
            },
        }   
    };
</script>
<style></style>