import Service from '@/helpers/service'

export default {

    index(page, params) {
        return Service().get('MaterialRequestList/material-request?page=' + page, {params});
    }, 
    getTotalItem(params) {
        return Service().get('/emcr/total-mcr-item/'+ params);
    }, 
}